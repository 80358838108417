import React from "react"
import Layout from "../components/Layout"
import StreamingPlatform from "../components/StreamingPlatform"
import Playlist from "../components/Playlist"
import negativLandingImg from "../../public/negativLanding.jpg"

export default function Home() {
  return (
    <Layout>
      <img
        src={negativLandingImg}
        alt="negativ holding head near cement cylinder"
        className="w-full"
      />
      <Playlist />
      <StreamingPlatform />
    </Layout>
  )
}
