import React from "react"

export default function Playlist() {
  return (
    <div className="w-full h-44 sm:h-96 md:h-120 -mt-6">
      <iframe
        title="Oak Wessy"
        className="w-full h-full"
        src="https://www.youtube.com/embed/YLHIkli9PsQ"
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}
