import React from "react"
import SafeAnchor from "./SafeAnchor"
import soundcloudLogoImg from "../../public/soundcloudlogo.svg"
import appleMusicLogoImg from "../../public/applemusiclogo.png"
import spotifyLogoImg from "../../public/spotifylogo.png"
import unitedMastersLogoImg from "../../public/unitedmasterslogo.png"
// import tidalLogoImg from "../../public/tidallogo.png"

const streamingPlatformComponent = streamingList =>
  streamingList.map(streamingPlatform => {
    return (
      <div className={"mx-2 my-4"} key={streamingPlatform.name}>
        <SafeAnchor
          linkUrl={streamingPlatform.link}
          classes="flex flex-col justify-between text-center h-full"
        >
          <img
            src={streamingPlatform.imageLink}
            alt={streamingPlatform.altText}
            className={"md:w-64 sm:w-32"}
          />
          <p>{streamingPlatform.name}</p>
        </SafeAnchor>
      </div>
    )
  })

export default function StreamingPlatform() {
  const streamingPlatforms = [
    {
      name: "Soundcloud",
      link: "https://soundcloud.com/whoisnegativ",
      imageLink: soundcloudLogoImg,
      altText: "Soundcloud logo",
    },
    {
      name: "Spotify",
      link:
        "https://open.spotify.com/user/dj9cx5u8ky9j77lmu2grjfers?si=kisR9W14T0q8kFhMr8ZcPw",
      imageLink: spotifyLogoImg,
      altText: "Spotify logo",
    },
    // {
    //   name: "Tidal",
    //   link: "https://tidal.com/browse/album/167496795",
    //   imageLink: "/static/Tidal-01.png",
    //   altText: "Tidal logo",
    // },
    {
      name: "Apple Music",
      link: "https://music.apple.com/ca/artist/negativ/1550579307",
      imageLink: appleMusicLogoImg,
      altText: "Apple Music logo",
    },
    {
      name: "United Masters",
      link: "https://unitedmasters.com/igobyjude",
      imageLink: unitedMastersLogoImg,
      altText: "United Masters logo",
    },
  ]
  return (
    <div>
      <div class="flex flex-wrap justify-center items-center border">
        <h2>Presave my new single here: &ensp;</h2>
        <a class="text-blue-600" href="https://untd.io/r/ADWqMDQ6XWLS">
          https://untd.io/r/ADWqMDQ6XWLS
        </a>
      </div>
      <div
        className={"flex flex-row justify-around flex-wrap content-center my-4"}
      >
        {streamingPlatformComponent(streamingPlatforms)}
      </div>
    </div>
  )
}
